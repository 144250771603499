import { CustomResponse, DataProvider } from "@refinedev/core";
import { authProvider } from "~/authProvider";
import { callAPI, sha1 } from "~/utils/utils";

export const userProvider = (type: string): Omit<Required<DataProvider>, "createMany" | "updateMany" | "deleteMany" | "getMany" | "getApiUrl"> => ({
    getList: async ({ resource, pagination, filters, sorters }) => {
        const user = await authProvider.getIdentity();
        const { current = 1, pageSize = 10 } = pagination ?? {};
        const data = await callAPI("auth/users", { userID: user.id, skip: (current - 1) * pageSize, limit: pageSize, status: -100, userType: type }, user.session)
        return {
            data: data.user,
            total: data.total,
        };
    },

    create: async ({ resource, variables }: { resource: string, variables: any }) => {
        const pwd = sha1(variables["password"])
        const v = { ...variables, userType: type, loginType: "UserPassword", password: pwd }
        const user = await authProvider.getIdentity();
        const data = await callAPI("auth/create_user", { userID: user.id, user: v }, user.session)
        if (data.status != 1) {
            throw {
                message: "Error:" + data.status,
                statusCode: data.status,
            }
        }
        return { data: data.user }
    },

    update: async ({ resource, id, variables }) => {
        throw {
            message: "Update not supported",
        }
    },

    getOne: async ({ resource, id }) => {
        const user = await authProvider.getIdentity();
        const data = await callAPI("auth/admin/user", { userID: user.id, target: id }, user.session)
        return { data: data.user }
    },

    deleteOne: async ({ resource, id, variables }) => {
        throw {
            message: "Delete not supported",
        }
    },
    custom: async ({ url, payload }: { url: string, payload?: any }): Promise<CustomResponse<any>> => {
        if (url == "resetSecret") {
            const user = await authProvider.getIdentity();
            const data = await callAPI("auth/admin/reset/secret", { userID: user.id, target: payload["id"] }, user.session)
            if (data.status != 1) {
                throw "Error:" + data.status
            }
            return { data: null }
        } else if (url = "resetPwd") {
            const user = await authProvider.getIdentity();
            const data = await callAPI("auth/change_password", { userID: user.id, target: payload["id"], newPassword: sha1(payload["password"]), loginType: "UserPassword" }, user.session)
            if (data.status != 1) {
                throw "Error:" + data.status
            }
            return { data: null }
        }
        throw "Unimplemented";
    },
});
