import { authProvider } from "~/authProvider";
import { callAPI } from "~/utils/utils";
import { BaseKey, CustomResponse, DataProvider, LogicalFilter } from "@refinedev/core";

export const productProvider = (): Omit<Required<DataProvider>, "createMany" | "updateMany" | "deleteMany" | "getMany" | "getApiUrl"> => ({
  getList: async ({ resource, pagination, filters, sorters }) => {
    const user = await authProvider.getIdentity();
    const params: { [key: string]: any } = { userID: user.id };
    if (filters) {
      for (let i = 0; i < filters.length; i++) {
        const ele = filters[i] as LogicalFilter;
        params[ele.field] = ele.value;
      }
    }
    if (params["status"] == null) {
      params["status"] = -1;
    } else {
      params["status"] = parseInt(params["status"]);
    }
    const data = await callAPI("product/list", params, user.session);
    return {
      data: data.products,
      total: data.total,
    };
  },

  create: async ({ resource, variables }: { resource: string; variables: any }) => {
    if (variables.image && variables.image.includes("https:/") && !variables.image.includes("https://")) {
      variables.image = variables.image.replace("https:/", "https://");
    }
    if (variables.bigImage && variables.bigImage.includes("https:/") && !variables.bigImage.includes("https://")) {
      variables.bigImage = variables.bigImage.replace("https:/", "https://");
    }
    const user = await authProvider.getIdentity();
    const data = await callAPI("admin/product/add", { userID: user.id, product: variables }, user.session);
    if (data.status != 1) {
      throw {
        message: "Error:" + data.status,
        statusCode: data.status,
      };
    }
    return { data: data.product };
  },

  update: async ({ resource, id, variables }: { resource: string; id: BaseKey; variables: any }) => {
    variables["id"] = id;
    if (variables.image && variables.image.includes("https:/") && !variables.image.includes("https://")) {
      variables.image = variables.image.replace("https:/", "https://");
    }
    if (variables.bigImage && variables.bigImage.includes("https:/") && !variables.bigImage.includes("https://")) {
      variables.bigImage = variables.bigImage.replace("https:/", "https://");
    }
    const user = await authProvider.getIdentity();
    const data = await callAPI("admin/product/edit", { userID: user.id, product: variables }, user.session);
    if (data.status != 1) {
      throw {
        message: "Error:" + data.status,
        statusCode: data.status,
      };
    }
    return { data: data.product };
  },

  getOne: async ({ resource, id }) => {
    const user = await authProvider.getIdentity();
    const data = await callAPI("admin/product/get", { userID: user.id, product: id }, user.session);
    if (data.status != 1) {
      throw {
        message: "Error:" + data.status,
        statusCode: data.status,
      };
    }
    if (data.product.image.includes("https:/") && !data.product.image.includes("https://")) {
      data.product.image = data.product.image.replace("https:/", "https://");
    }
    if (data.product.bigImage.includes("https:/") && !data.product.bigImage.includes("https://")) {
      data.product.bigImage = data.product.bigImage.replace("https:/", "https://");
    }
    return { data: data.product };
  },

  deleteOne: async ({ resource, id }: { resource: string; id: BaseKey }): Promise<CustomResponse<any>> => {
    const user = await authProvider.getIdentity();
    const data = await callAPI("admin/product/del", { userID: user.id, target: id }, user.session);
    if (data.status != 1) {
      throw {
        message: "Error:" + data.status,
        statusCode: data.status,
      };
    }
    return { data: null };
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    throw {
      message: "getOne not supported",
    };
  },
});
