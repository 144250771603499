import type { MetaFunction } from "@remix-run/node";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";

import { AuthPage, notificationProvider } from "@refinedev/antd";
import { Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import routerProvider, { UnsavedChangesNotifier } from "@refinedev/remix-router";

import { ColorModeContextProvider } from "@contexts";
import resetStyle from "@refinedev/antd/dist/reset.css";
import dataProvider from "@refinedev/simple-rest";
import { authProvider } from "~/authProvider";
import { userProvider } from "./providers/user";
import { productProvider } from "./providers/product";
import { orderProvider } from "./providers/order";
import { announcementProvider } from "./providers/announcement";
import { redeemProvider } from "./providers/redeem";

const API_URL = "https://api.fake-rest.refine.dev";

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "New Remix + Refine App",
  viewport: "width=device-width,initial-scale=1",
});

export default function App() {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <RefineKbarProvider>
              <Refine
                routerProvider={routerProvider}
                dataProvider={{
                  default: dataProvider("https://api.niseclub.com"),
                  user: userProvider(""),
                  admin: userProvider("admin"),
                  system: userProvider("system"),
                  order: orderProvider(),
                  product: productProvider(),
                  announcement: announcementProvider(),
                  redeem: redeemProvider(),
                }}
                notificationProvider={notificationProvider}
                resources={[
                  {
                    name: "users",
                  },
                  {
                    name: "member",
                    list: "/users/list/member",
                    meta: {
                      parent: "users",
                      dataProviderName: "user",
                    },
                  },
                  {
                    name: "admin",
                    list: "/users/list/admin",
                    create: "/users/create/admin",
                    edit: "/users/edit/admin/:id",
                    show: "/users/show/admin/:id",
                    meta: {
                      parent: "users",
                      dataProviderName: "admin",
                    },
                  },
                  {
                    name: "system",
                    list: "/users/list/system",
                    create: "/users/create/system",
                    edit: "/users/edit/system/:id",
                    show: "/users/show/system/:id",
                    meta: {
                      parent: "users",
                      dataProviderName: "system",
                    },
                  },
                  {
                    name: "announcement",
                    list: "/announcements",
                    create: "/announcements/create",
                    edit: "/announcements/edit/:id",
                    show: "/announcements/show/:id",
                    meta: {
                      canDelete: true,
                      dataProviderName: "announcement",
                    },
                  },
                  {
                    name: "products",
                    list: "/products",
                    create: "/products/create",
                    edit: "/products/edit/:id",
                    show: "/products/show/:id",
                    meta: {
                      canDelete: true,
                      dataProviderName: "product",
                    },
                  },
                  {
                    name: "orders",
                    list: "/orders",
                    edit: "/orders/edit/:id",
                    show: "/orders/show/:id",
                    meta: {
                      canDelete: true,
                      canCreate: false,
                      dataProviderName: "order",
                    },
                  },
                  {
                    name: "redeems",
                    list: "/redeems",
                    edit: "/redeems/edit/:id",
                    show: "/redeems/show/:id",
                    meta: {
                      canDelete: true,
                      canCreate: false,
                      dataProviderName: "redeem",
                    },
                  },
                ]}
                authProvider={authProvider}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                }}
              >
                <>
                  <Outlet />
                  <UnsavedChangesNotifier />
                  <RefineKbar />
                </>
              </Refine>
            </RefineKbarProvider>
          </ColorModeContextProvider>
        </RefineKbarProvider>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

export function links() {
  return [{ rel: "stylesheet", href: resetStyle }];
}
