import { authProvider } from "~/authProvider";
import { callAPI } from "~/utils/utils";
import { BaseKey, CustomResponse, DataProvider, LogicalFilter } from "@refinedev/core";

export const announcementProvider = (): Omit<Required<DataProvider>, "createMany" |"update" | "updateMany" | "deleteMany" | "getMany" | "getApiUrl"> => ({
  getList: async ({ resource, pagination, filters, sorters }) => {
    const user = await authProvider.getIdentity();
    const params: { [key: string]: any } = { userID: user.id };
    const data = await callAPI("announcement/list", params, user.session);
    return {
      data: data.announcements,
      total: data.total,
    };
  },

  create: async ({ resource, variables }: { resource: string; variables: any }) => {
    const user = await authProvider.getIdentity();
    const data = await callAPI("admin/announcement/add", { userID: user.id, announcement: variables }, user.session);
    if (data.status != 1) {
      throw {
        message: "Error:" + data.status,
        statusCode: data.status,
      };
    }
    return { data: data.announcement };
  },

  getOne: async ({ resource, id }) => {
    const user = await authProvider.getIdentity();
    const data = await callAPI("admin/announcement/get", { userID: user.id, target: id }, user.session);
    if (data.status != 1) {
      throw {
        message: "Error:" + data.status,
        statusCode: data.status,
      };
    }
    return { data: data.announcement };
  },

  deleteOne: async ({ resource, id }: { resource: string; id: BaseKey }): Promise<CustomResponse<any>> => {
    const user = await authProvider.getIdentity();
    const data = await callAPI("admin/announcement/del", { userID: user.id, target: id }, user.session);
    if (data.status != 1) {
      throw {
        message: "Error:" + data.status,
        statusCode: data.status,
      };
    }
    return { data: null };
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    throw {
      message: `${url}, ${method} not supported`,
    };
  },
});
