import { authProvider } from "~/authProvider";
import { callAPI } from "~/utils/utils";
import { BaseKey, DataProvider, LogicalFilter } from "@refinedev/core";

export const redeemProvider = (): Omit<
  Required<DataProvider>,
  "create" | "update" | "createMany" | "updateMany" | "deleteMany" | "deleteOne" | "getMany" | "getApiUrl"
> => ({
  getList: async ({ resource, pagination, filters, sorters }) => {
    const user = await authProvider.getIdentity();
    const params: { [key: string]: any } = { userID: user.id };
    if (filters) {
      for (let i = 0; i < filters.length; i++) {
        const ele = filters[i] as LogicalFilter;
        params[ele.field] = ele.value;
      }
    }
    if (params["status"] != null) {
      params["status"] = [parseInt(params["status"])];
    }
    const data = await callAPI("redeem/list", params, user.session);
    return {
      data: data.redeems,
      total: data.total,
    };
  },

  getOne: async ({ resource, id }) => {
    const user = await authProvider.getIdentity();
    const data = await callAPI("redeem/get", { userID: user.id, target: id }, user.session);
    if (data.status != 1) {
      throw {
        message: "Error:" + data.status,
        statusCode: data.status,
      };
    }
    return { data: data.redeem };
  },

  custom: async ({ url, payload }: { url: string; payload?: any }) => {
    if (url == "updateRedeem") {
      const user = await authProvider.getIdentity();
      const post = {
        userID: user.id,
        target: payload["id"],
        status: parseInt(payload["status"]),
        remark: payload["remark"],
        points: parseInt(payload["points"]),
      };
      const data = await callAPI("admin/redeem/edit", post, user.session);
      if (data.status != 1) {
        throw {
          message: "Error:" + data.status,
          statusCode: data.status,
        };
      }
      return { data: data.order };
    }
    throw {
      message: `${url} not supported`,
    };
  },
});
